.info-card {
    background-color: rgb(0, 174, 237);
    border-radius: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-left: 4px solid;
    /* Colored border based on type */
}

.info-card-body {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
}

.info-card-text {
    display: flex;
    flex-direction: column;
}

.info-card-title {
    font-size: 1.1rem;
}

.info-card-value {
    font-size: 1.5rem;
    font-weight: bolder;
    /* Larger font size for better visibility */
}

.info-card-icon {
    font-size: 2rem;
    /* Larger icons */
    padding-left: 20px;
}

.border-left-ProducaoDiaria {
    border-color: white;
}

.border-left-MediaVaca {
    border-color: white;
}

.border-left-AnimaisAtivos {
    border-color: white;
}

.border-left-AnimaisEmLactacao {
    border-color: white;
}