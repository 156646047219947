.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(0, 174, 237);
    text-align: center;
    position: relative;
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

.register-logo {
    width: 100px;
    margin-bottom: 2rem;
}

.register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    max-width: 400px;
}

.register-form input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    font-size: 1rem;
}

.register-form input::placeholder {
    color: white;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.contact-container input {
    width: 48%;
}

.register-button {
    background-color: white;
    color: rgb(0, 174, 237);
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.register-button:hover {
    background-color: rgb(200, 200, 200);
}