.sidebar {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: calc(100vh - 60px); /* Subtrair a altura do header */
  width: 250px;
  transition: width 0.3s;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 60px; /* Iniciar abaixo do header */
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: justify-content 0.3s;
}

.sidebar.collapsed .sidebar-toggle {
  justify-content: center;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  justify-content: flex-start;
}

.sidebar-item:hover {
  background-color: #e0e0e0;
}

.sidebar-item svg {
  font-size: 24px;
  transition: font-size 0.3s, margin-right 0.3s;
}

.sidebar.collapsed .sidebar-item {
  justify-content: center;
}

.sidebar.collapsed .sidebar-item svg {
  font-size: 32px;
  margin-right: 0;
}

.sidebar-item span {
  margin-left: 10px;
  transition: opacity 0.3s;
}

.sidebar.collapsed .sidebar-item span {
  opacity: 0;
}

.sidebar-submenu {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.sidebar-submenu .sidebar-item {
  padding: 10px 0;
}
