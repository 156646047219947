.assinatura-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    height: 100vh;
    background-color: rgb(0, 174, 237);
    padding: 2rem;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.plano {
    background-color: white;
    border-radius: 8px;
    color: black;
    padding: 2rem;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 1rem;
}

.plano h2 {
    background-color: rgb(39, 0, 110);
    color: white;
    padding: 1rem;
    border-radius: 8px 8px 0 0;
}

.premium h2 {
    background-color: rgb(0, 136, 194);
}

.padrao h2 {
    background-color: rgb(0, 174, 237);
}

.anuncios h2 {
    background-color: rgb(150, 215, 10);
}

.subtitulo {
    font-size: 1rem;
    color: grey;
}

.preco {
    font-size: 1.5rem;
    margin: 1rem 0;
}

.detalhes,
.beneficios {
    text-align: left;
    margin-bottom: 1rem;
}

.beneficios p {
    margin: 0.5rem 0;
    position: relative;
    padding-bottom: 0.5rem;
}

.beneficios p::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 0;
}

.beneficios p:last-child::after {
    content: none;
}

/* Media queries para responsividade */
@media (max-width: 768px) {
    .assinatura-container {
        flex-direction: column;
        align-items: center;
        height: auto;
        padding: 1rem;
    }

    .plano {
        width: 90%;
        margin: 1rem 0;
    }
}