.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  background-color: rgb(0, 174, 237);
  padding: 0 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-right {
  display: flex;
  align-items: center;
  color: white;
}

.header-icon {
  font-size: 20px;
  margin-left: 10px;
  margin-right: 0px;
  cursor: pointer;
}

.header-icon:hover {
  color: black;
}
