.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(0, 174, 237);
    text-align: center;
}

.login-logo {
    width: 150px;
    margin-bottom: 2rem;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    max-width: 400px;
}

.login-form input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    font-size: 1rem;
}

.login-form input::placeholder {
    color: white;
}

.button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.login-button {
    background-color: rgb(0, 136, 194);
    color: white;
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1rem;
}

.login-button:hover {
    background-color: rgb(39, 0, 110);
}

.register-button {
    background-color: white;
    color: rgb(0, 174, 237);
    padding: 1rem 2.5rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
}

.register-button:hover {
    background-color: rgb(200, 200, 200);
}

.forgot-password {
    color: white;
    margin-top: 1rem;
}

.forgot-password:hover {
    text-decoration: underline;
    
}