.home-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
}

.home-container {
  display: flex;
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: margin-left 0.3s;
  margin-left: 250px; /* Tamanho padrão da sidebar expandida */
}

.sidebar.collapsed ~ .main-content {
  margin-left: 80px; /* Tamanho da sidebar recolhida */
}

.content {
  padding: 1rem;
  margin-top: 0px; /* Ajustar para a altura correta do header */
  flex-grow: 1;
  overflow: hidden;
}

.info-boxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-box {
  background-color: rgb(0, 174, 237);
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px); 
  margin: 7px;
  text-align: center;
  box-sizing: border-box;
  max-width: calc(33.333% - 20px);
}

@media (max-width: 1024px) {
  .info-box {
    flex: 1 1 calc(45% - 20px);
    max-width: calc(45% - 20px);
  }
}

@media (max-width: 768px) {
  .info-box {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}
.home-container {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 60px); /* Subtract header height */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow: auto;
}

.content {
  width: 100%;
}

.info-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Responsive grid */
  gap: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .info-boxes {
    grid-template-columns: 1fr; /* One column on small screens */
  }
}
