.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgb(0, 174, 237);
    text-align: center;
  }
  
  .forgot-password-logo {
    width: 150px;
    margin-bottom: 2rem;
  }
  
  .forgot-password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%;
    max-width: 400px;
  }
  
  .forgot-password-form input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border: none;
    border-bottom: 2px solid white;
    background-color: transparent;
    color: white;
    font-size: 1rem;
  }
  
  .forgot-password-form input::placeholder {
    color: white;
  }
  
  .forgot-password-button {
    background-color: white;
    color: rgb(0, 174, 237);
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .forgot-password-button:hover {
    background-color: rgb(200, 200, 200);
  }
  